// let wallet_services = '/wallet-services-dev'; //dev
let wallet_services = '/wallet-services-test'; //test
let isofhcare_service = '/isofhcare'; //dev
// let isofhcare_service = ""; //test

function getConfig() {
    const domain = typeof window !== 'undefined' ? window && window.location && window.location.origin : '';
    let url;
    const ISOFHCARE_PRODUCTION = 'https://api-produce.isofhcare.com';
    const ISOFHCARE_STABLE = 'http://10.0.0.98:8000';
    const ISOFHCARE_TEST = 'http://10.0.0.98:8000';
    const ISOFHCARE_TEST_PUBLIC = 'https://register.isofh.com';
    switch (domain) {
        case 'https://admin.isofhcare.com': // production
        case 'http://35.197.145.195:8082': // production
            url = {
                server_url: ISOFHCARE_PRODUCTION,
                serverConsultation: `${ISOFHCARE_PRODUCTION}/consultation`,
                serverNews: `${ISOFHCARE_PRODUCTION}/news`,
                serviceSchedule: ISOFHCARE_PRODUCTION,
                serviceBooking: ISOFHCARE_PRODUCTION,
                serviceImages: ISOFHCARE_PRODUCTION,
                SERVICE_PROFILE: `${ISOFHCARE_PRODUCTION}/profile/v1/`,
                resource_url: 'https://isofhcare-backup.s3-ap-southeast-1.amazonaws.com/',
                requestPayment: ISOFHCARE_PRODUCTION,
                covid: 'http://10.0.50.163:49396',
                chart: `${ISOFHCARE_PRODUCTION}/order-dashboard`,
                tracking: `${ISOFHCARE_PRODUCTION}/user-dashboard`,
                patient: 'http://35.197.134.160:50382',
                serverLevelCard: `${ISOFHCARE_PRODUCTION}/promotion`,
            };
            break;
        case 'http://10.0.0.88:8280': // stable
            url = {
                server_url: ISOFHCARE_STABLE,
                serverConsultation: `${ISOFHCARE_STABLE}/consultation`,
                serverNews: `${ISOFHCARE_STABLE}/news`,
                serviceSchedule: ISOFHCARE_STABLE,
                serviceBooking: ISOFHCARE_STABLE,
                serviceImages: ISOFHCARE_STABLE,
                SERVICE_PROFILE: `${ISOFHCARE_STABLE}/profile/v1/`,
                resource_url: 'https://isofhcare-backup.s3-ap-southeast-1.amazonaws.com/',
                requestPayment: ISOFHCARE_STABLE,
                covid: 'http://10.0.50.163:49396',
                chart: `${ISOFHCARE_STABLE}/order-dashboard`,
                tracking: `${ISOFHCARE_STABLE}/user-dashboard`,
                patient: 'http://35.197.134.160:50382',
                serverLevelCard: `${ISOFHCARE_STABLE}/promotion`,
            };
            break;
        case 'https://support-admin.isofh.com':
            url = {
                server_url: ISOFHCARE_TEST_PUBLIC,
                serverConsultation: `${ISOFHCARE_TEST_PUBLIC}/consultation`,
                serverNews: `${ISOFHCARE_TEST_PUBLIC}/news`,
                serviceSchedule: ISOFHCARE_TEST_PUBLIC,
                serviceBooking: ISOFHCARE_TEST_PUBLIC,
                serviceImages: ISOFHCARE_TEST_PUBLIC,
                SERVICE_PROFILE: `${ISOFHCARE_TEST_PUBLIC}/profile/v1/`,
                resource_url: 'https://isofhcare-backup.s3-ap-southeast-1.amazonaws.com/',
                requestPayment: ISOFHCARE_TEST_PUBLIC,
                covid: 'http://10.0.50.163:49396',
                chart: `${ISOFHCARE_TEST_PUBLIC}/order-dashboard`,
                tracking: `${ISOFHCARE_TEST_PUBLIC}/user-dashboard`,
                patient: 'http://35.197.134.160:50382',
                serverLevelCard: `${ISOFHCARE_TEST_PUBLIC}/promotion`,
            };
            break;
        case 'http://10.0.0.98:8280':
        case 'http://localhost:3000':
            url = {
                server_url: ISOFHCARE_TEST,
                serverConsultation: `${ISOFHCARE_TEST}/consultation`,
                serverNews: `${ISOFHCARE_TEST}/news`,
                serviceSchedule: ISOFHCARE_TEST,
                serviceBooking: ISOFHCARE_TEST,
                serviceImages: ISOFHCARE_TEST,
                SERVICE_PROFILE: `${ISOFHCARE_TEST}/profile/v1/`,
                resource_url: 'https://isofhcare-backup.s3-ap-southeast-1.amazonaws.com/',
                requestPayment: ISOFHCARE_TEST,
                covid: 'http://10.0.50.163:49396',
                chart: `${ISOFHCARE_TEST}/order-dashboard`,
                tracking: `${ISOFHCARE_TEST}/user-dashboard`,
                patient: 'http://35.197.134.160:50382',
                serverLevelCard: `${ISOFHCARE_TEST}/promotion`,
            };
            break;
        case 'http://10.0.0.98:8282':
            url = {
                server_url: ISOFHCARE_STABLE,
                serverConsultation: `${ISOFHCARE_STABLE}/consultation`,
                serverNews: `${ISOFHCARE_STABLE}/news`,
                serviceSchedule: ISOFHCARE_STABLE,
                serviceBooking: ISOFHCARE_STABLE,
                serviceImages: ISOFHCARE_STABLE,
                SERVICE_PROFILE: `${ISOFHCARE_STABLE}/profile/v1/`,
                resource_url: 'https://isofhcare-backup.s3-ap-southeast-1.amazonaws.com/',
                requestPayment: ISOFHCARE_STABLE,
                covid: 'http://10.0.50.163:49396',
                chart: `${ISOFHCARE_STABLE}/order-dashboard`,
                tracking: `${ISOFHCARE_STABLE}/user-dashboard`,
                patient: 'http://35.197.134.160:50382',
                serverLevelCard: `${ISOFHCARE_STABLE}/promotion`,
            };
            break;
        default:
            // test with localhost
            url = {
                server_url: ISOFHCARE_TEST,
                serverConsultation: `${ISOFHCARE_TEST}/consultation`,
                serverNews: `${ISOFHCARE_TEST}/news`,
                serviceSchedule: ISOFHCARE_TEST,
                serviceBooking: ISOFHCARE_TEST,
                serviceImages: ISOFHCARE_TEST,
                SERVICE_PROFILE: `${ISOFHCARE_TEST}/profile/v1/`,
                resource_url: 'https://isofhcare-backup.s3-ap-southeast-1.amazonaws.com/',
                requestPayment: ISOFHCARE_TEST,
                covid: 'http://10.0.50.163:49396',
                chart: `${ISOFHCARE_TEST}/order-dashboard`,
                tracking: `${ISOFHCARE_TEST}/user-dashboard`,
                patient: 'http://35.197.134.160:50382',
                serverLevelCard: `${ISOFHCARE_TEST}/promotion`,
            };
            break;
    }
    return url;
}
export const API_ENDPOINT = getConfig();

const CATALOG = `${API_ENDPOINT?.serviceSchedule}/catalog/v1`;
const APPOINTMENT = `${API_ENDPOINT?.serviceBooking}/appointment/v1`;
const PAYMENT = `${API_ENDPOINT?.requestPayment}/payment/v1`;
const CONSULTATION = `${API_ENDPOINT?.serverConsultation}`;
const COVID = `${API_ENDPOINT?.covid}`;
const CHART = `${API_ENDPOINT?.chart}`;
const TRACKING = `${API_ENDPOINT?.tracking}`;

export default {
    isofhcare_service,
    isofhcare_v1: {
        catalog: CATALOG,
        appointment: APPOINTMENT,
        payment: PAYMENT,
    },
    key: {
        storage: {
            current_account: 'CURRENT_USER',
        },
    },
    action: {
        action_user_login: 'ACTION_USER_LOGIN',
        action_user_logout: 'ACTION_USER_LOGOUT',
    },
    message: {
        user: {
            create_error: 'Tạo mới tài khoản không thành công!',
            update_error: 'Cập nhật tài khoản không thành công!',
            error_code_2: 'SĐT đã được sử dụng trong hệ thống. Vui lòng sử dụng SĐT khác!',
            error_code_3: 'Email đã được sử dụng trong hệ thống. Vui lòng sử dụng Email khác!',
            error_code_4:
                'Số chứng chỉ hành nghề đã tồn tại trên hệ thống. Vui lòng sử dụng Số chứng chỉ hành nghề khác!',
            error_code_5: 'Username đã tồn tại trên hệ thống. Vui lòng sử dụng Username khác!',
        },
        post: {
            approved_success: 'Duyệt câu hỏi và gán cho bác sĩ thành công!',
            approved_error: 'Duyệt câu hỏi không thành công!',
        },
        hospital: {
            create_error: 'Tạo mới tài khoản không thành công!',
            update_error: 'Cập nhật tài khoản không thành công!',
        },
    },
    api: {
        revenue: {
            getRevenue: `${APPOINTMENT}/revenue-report`,
        },
        pharmacy: {
            pharmacies: `/pharmacy/api/pharmacies`,
            medicine_groups: `/pharmacy/api/medicine-groups`,
            medicines: `/pharmacy/api/medicines`,
            orders: `/pharmacy/api/orders`,
            prescriptions: `/pharmacy/api/prescriptions`,
            byIds: `/pharmacy/api/pharmacies`,
            exportfile: `/pharmacy/api/orders/export`,
            get_order: `/pharmacy/fulfillments/api/fulfillments`,
            create_order: `/pharmacy/api/orders/:orderId/fulfillments`,
            cancel_fulfillment: `/pharmacy/api/orders/:fulfillmentId`,
            getprofile: `/profile/api/users/:userId/profile`,
        },
        accountCompany: {
            getAll: '/business/v1/companies',
            // get_deactivate: '/business/v1/companies',
            // get_activate: '/business/v1/companies'
        },
        earn_point: {
            get_config: `/promotion/v1/setting/increase-point`,
            update_config: `/promotion/v1/setting/increase-point`,
            get_member_card: `/promotion/v1/memberships/filter`,
            export_member_card: `/isofhcare/memberships`,
            get_level_card_all: `/promotion/v1/card-levels`,
            get_member_detail: `/promotion/v1/memberships/:id`,
            get_level_card: `/promotion/v1/card-levels/filter`,
            crud_level_card: `/promotion/v1/card-levels`,
            get_member_detail_history: `/promotion/v1/memberships/:id/transactions`,
            get_list_redeem_point: `/promotion/v1/loyalty-items/filter`,
            update_gift: `/promotion/v1/loyalty-items/:id`,
            create_gift: `/promotion/v1/loyalty-items`,
            get_list_redeem_future: `/promotion/v1/promotions/redemption-in-future`,
            get_detail_gift: `/promotion/v1/loyalty-items/:id`,
            get_history_gift: `/promotion/v1/loyalty-items/redemptions/filter`,
            accept_gift: `/promotion/v1/loyalty-items/redemptions/:id/send`,
            get_gift: `/promotion/v1/loyalty-items`,
            // create_level_card: `/promotion/v1/card-levels`,
            // update_level_card: `/promotion/v1/card-levels`,
        },
        ctkm: `/promotion/v1/promotions/filter`,
        gift: {
            filter: `/promotion/v1/gifts/filter`,
        },
        roles: `${isofhcare_service}/admins/roles`,
        permissions: `${isofhcare_service}/admins/permissions`,
        admins: `${isofhcare_service}/admins`,
        authorities: `${isofhcare_service}/user/me`,
        search: `${isofhcare_service}/admins/medical-profiles/search`,
        filter: `${CATALOG}/convenients/filter`,
        logs: isofhcare_service + '/log-admin',
        validateToken: `${API_ENDPOINT.server_url}${isofhcare_service}/validate-token`,
        user: {
            byIds: isofhcare_service + '/user/by-ids',
            uploadUser: isofhcare_service + '/user/upload-user',
            userByPhones: isofhcare_service + '/user/by-phones',
            search: isofhcare_service + '/user/search',
            filter: isofhcare_service + '/user/filter',
            login: isofhcare_service + '/user/login',
            logout: isofhcare_service + '/user/logout',
            block: isofhcare_service + '/user/block',
            create: isofhcare_service + '/user/create',
            update: isofhcare_service + '/user/update',
            active: isofhcare_service + '/user/set-active',
            detail: isofhcare_service + '/user/get-detail',
            getProfileByID: isofhcare_service + '/admins/users/:userId/profiles',
            updatePassword: isofhcare_service + '/user/update-password',
            getListBySpecialist: isofhcare_service + '/user/getListBySpecialist',
            getUserInActive: `${API_ENDPOINT.patient}/patient-filters`,
            resetPassword: isofhcare_service + '/user/reset-password/:userId',
            activeUser: isofhcare_service + '/user/active/:id',
            set_password: isofhcare_service + '/user/reset-password/',
            create_patient: isofhcare_service + '/admins/hospitals/:hospitalId/users',
            update_patient: isofhcare_service + '/admins/hospitals/:hospitalId/medical-profiles/:medicalProfileId',
            detail_patient: isofhcare_service + '/admins/hospitals/:hospitalId/users/:userId/medical-profile',
            downloadFileUsers:
                'https://isofhcare-backup.s3-ap-southeast-1.amazonaws.com/files/import-user-moi--hon-5000_8bddd623_84dd_4be2_9a2c_fc2d4e6a7a42.xlsx',
            lock: isofhcare_service + '/admins/:id/locked',
            unlock: isofhcare_service + '/admins/:id/unlocked',
        },
        image: {
            upload: `${API_ENDPOINT?.serviceImages}/isofhcare/upload/image`,
        },
        file: {
            upload: isofhcare_service + '/upload/file',
        },
        specialist: {
            getAll: isofhcare_service + '/specialist/get-all',
            search: isofhcare_service + '/specialist/search',
            create: isofhcare_service + '/specialist/create',
            delete: isofhcare_service + '/specialist/delete',
            update: isofhcare_service + '/specialist/update',
        },
        specialty: {
            all: `${CATALOG}/specializations/filter`,
            remove: `${CATALOG}/specialization/:specializationId/remove`,
            create: `${CATALOG}/specialization`,
            select: `${CATALOG}/specialization/select`,
            deleteByHospital: `${CATALOG}/:hospitalId/hospital/:specialtyId/specialization`,
            updateByHospital: `${CATALOG}/:hospitalId/hospital/:listSpecialtyId/specialization`,
            update: `${CATALOG}/:specializationId/specialization`,
        },
        serviceType: {
            getAll: isofhcare_service + '/service-type/get-all',
            search: isofhcare_service + '/service-type/search',
            create: isofhcare_service + '/service-type/create',
            delete: isofhcare_service + '/service-type/delete',
            update: isofhcare_service + '/service-type/update',
        },
        service: {
            search: isofhcare_service + '/service/search',
            byIds: CATALOG + '/medicalservices/by-ids',
            type: isofhcare_service + '/service-type/search',
            special: isofhcare_service + '/specialist/get-all',
            create: isofhcare_service + '/service/create',
            update: isofhcare_service + '/service/update',
            delete: isofhcare_service + '/service/delete',
            getAll: isofhcare_service + '/service/get-all',
            getByHospital: `${CATALOG}/medicalservice`,
            get_list_service: '/medicalservice',
            getByHospitalId: `${CATALOG}/medicalservice/:hospitalId/hospital`,
            detail: `${CATALOG}/medicalservice/:medicalserviceId`,
            searchMedicalService: `${CATALOG}/medicalservice/search`,
            highlight: `${CATALOG}/medicalservice/:medicalserviceId/highlight`,
            rejection: `${CATALOG}/medicalservice/:medicalserviceId/rejection`,
            approval: `${CATALOG}/medicalservice/:medicalserviceId/approval`,
            assignPromotion: `${CATALOG}/medicalservice/:medicalserviceId/assign-promotion?promotionId=:promotionId`,
            getMedicalServicesByHospitalId: `${CATALOG}/medicalservice/search?hospitalId=:hospitalId`,
            importRevenue: `${CATALOG}/medicalservices/import-revenue-percent`,
            getListSchedule: `${CATALOG}/medicals/:serviceId/timeslots`,
        },
        post: {
            search: isofhcare_service + '/post/search',
            assign: isofhcare_service + '/post/assign',
            approved: isofhcare_service + '/post/approved-post',
            approvedList: isofhcare_service + '/post/assign-list-post',
        },
        role: {
            search: isofhcare_service + '/roles/search',
            create: isofhcare_service + '/roles/create',
            delete: isofhcare_service + '/roles/delete',
            block: isofhcare_service + '/roles/block',
            getDetail: isofhcare_service + '/roles/get-detail',
            update: isofhcare_service + '/roles/update',
        },
        permission: {
            search: isofhcare_service + '/permission/search',
            create: isofhcare_service + '/permission/create',
            delete: isofhcare_service + '/permission/delete',
            update: isofhcare_service + '/permission/update',
        },
        comment: {
            search: isofhcare_service + '/comment/search',
            create: isofhcare_service + '/comment/create',
            delete: isofhcare_service + '/comment/delete',
            update: isofhcare_service + '/comment/update',
            acceptAsSolution: isofhcare_service + '/comment/accept-as-solution',
        },
        hospital: {
            getAll: isofhcare_service + '/hospital/get-all',
            getAllTracking: isofhcare_service + '/hospital/get-all?enable=true',
            create: isofhcare_service + '/hospital/create',
            delete: isofhcare_service + '/hospital/delete',
            getDetail: isofhcare_service + '/hospital/get-detail',
            search: isofhcare_service + '/hospital/search',
            select: CATALOG + '/hospitals/select',
            byIds: CATALOG + '/hospitals/by-ids',
            update: isofhcare_service + '/hospital/update',
            active: isofhcare_service + '/hospital/set-active',
            set_ticket: isofhcare_service + '/hospital/manager-reception',
            get_info_ticket: isofhcare_service + '/number-hospital/history',
            getHospitalbyDoctor: isofhcare_service + '/hospital/get-hospital-by-doctor',
            set_rank_hospital: `${isofhcare_service}/hospital/:hospitalId/rank/:rank/:ordinal`,
            get_list_hospital: CATALOG + '/hospitals',
            getAvailableRank: `${isofhcare_service}/hospital/rank-availible`,
            getVendorUsers: `${isofhcare_service}/hospital/:hospitalId/users`,
            createVendorUser: `${isofhcare_service}/hospital/:hospitalId/users`,
            changeVendorUserPassword: `${isofhcare_service}/hospital/:hospitalId/users/:userId/password`,
            activeVendorUser: `${isofhcare_service}/hospital/:hospitalId/users/:userId/active`,
            updateGeneralDisplayInfo: `${isofhcare_service}/hospital/:hospitalId/general`,
            updateContacts: `${isofhcare_service}/hospital/:hospitalId/contact`,
            updatePayment: `${isofhcare_service}/hospital/:hospitalId/payment`,
            chdk: `${isofhcare_service}/hospital/cau-hinh-dat-kham/`,
            orderNoti: `/order-notification/v1/email/`,
            updateSpecialistByHospital: `${CATALOG}/:hospitalId/hospital/:specializationIds/specialization`,
            getVendorPermission: isofhcare_service + '/vendor/:id/permissions',
            updateVendorPermission: isofhcare_service + '/vendor/:id/permissions',
        },
        wallet: {
            detail: wallet_services + '/partners',
            getDetail: wallet_services + '/online-transactions',
        },
        doctorInf: {
            active: isofhcare_service + '/doctorInf/active',
            create: isofhcare_service + '/doctorInf/create',
            // reject: isofhcare_service + "/doctorInf/reject",
            search: isofhcare_service + '/doctorInf/search',
            get_all: CATALOG + '/doctors/filter',
            confirm: CATALOG + '/doctors/confirm',
            reject: CATALOG + '/doctors/reject',
        },
        booking: {
            search: isofhcare_service + '/booking/search',
            approved: isofhcare_service + '/booking/approved-booking',
            update: isofhcare_service + '/booking/update',
            arrival: isofhcare_service + '/booking/update-arrival',
            checkin: isofhcare_service + '/booking/checkin',
            checkTransfer: isofhcare_service + '/booking/payTranfer',
            getDetail: isofhcare_service + '/booking/get-detail',
            get: APPOINTMENT,
            detail: `${APPOINTMENT}/:bookingId`,
            reject: `${APPOINTMENT}/:bookingId/reject`,
            approve: `${APPOINTMENT}/:bookingId/accept`,
            checkIn: `${APPOINTMENT}/:bookingId/checkin`,
            revert: `${APPOINTMENT}/:bookingId/revert`,
            updateBooking: `${APPOINTMENT}/:bookingId`,
            getDetailProfile: `${API_ENDPOINT.server_url}/profile/api/profiles/:userProfileId`,
            getBySchedule: `${APPOINTMENT}/doctor/:scheduleId/:date/schedule?page=:page&size=:size&sort=desc&properties=created`,
            getScheduleByDoctor: `${CATALOG}/schedules/block-times`,
            createBooking: `${APPOINTMENT}/admin`,
            exportExcel: `${APPOINTMENT}/report`,
            listsource: `${APPOINTMENT}/sources`,
        },
        zone: {
            getAll: isofhcare_service + '/zone/get-by-district',
            getByDistrict: isofhcare_service + '/zone/get-by-district/:districtId',
        },
        province: {
            getAll: isofhcare_service + '/province/get-all',
        },
        district: {
            getAll: isofhcare_service + '/district/get-all',
            getProvince: isofhcare_service + '/district/get-by-province',
            getByProvince: isofhcare_service + '/district/get-by-province/:provinceId',
        },
        medicine_category: {
            getAll: isofhcare_service + '/medicine-category/get-all',
            search: isofhcare_service + '/medicine-category/search',
            create: isofhcare_service + '/medicine-category/create',
            delete: isofhcare_service + '/medicine-category/delete',
            update: isofhcare_service + '/medicine-category/update',
        },
        medicine_store: {
            getAll: isofhcare_service + '/medicine-store/get-all',
            search: 'drugstores',
            searchExpression: 'drugstore/search',
            create: 'drugstore',
            get_drug: 'medicine/idStore/drugstore',
            updateLocation: 'drugstore/idStore/location',
            delete: isofhcare_service + '/medicine-store/delete',
            update: isofhcare_service + '/medicine-store/update',
            getAllMedicines: isofhcare_service + '/medicine-store/medicines/get-all',
            searchListDrug: isofhcare_service + '/orders/v1/prescriptions/search',
        },
        medicine_controll: {
            getAll: '/medicine/references',
        },
        userTracking: {
            search: isofhcare_service + '/user-tracking/search',
            total: isofhcare_service + '/user/total-user',
        },
        upload: {
            import: isofhcare_service + '/user/import-user',
        },
        advertise: {
            search: isofhcare_service + '/advertise/search',
            create: isofhcare_service + '/advertise/create',
            delete: isofhcare_service + '/advertise/delete',
        },
        category: {
            search: `${CATALOG}/categories/search`,
            create: `${CATALOG}/categories`,
            update: `${CATALOG}/categories/:categoryId`,
            delete: `${CATALOG}/categories/:categoryId`,
        },

        voucher: {
            search: `${isofhcare_service}/voucher`,
            create: `${isofhcare_service}/voucher`,
            update: `${isofhcare_service}/voucher/:id`,
            getHistory: `${isofhcare_service}/voucher/history/:voucherId`,
            importUser: `${isofhcare_service}/voucher/create/available`,
            generate: `${isofhcare_service}/voucher/generate`,
            available: `${isofhcare_service}/voucher/available/:voucherId`,
        },

        medicalRecord: {
            getDetail: `${isofhcare_service}/medical-records/get-detail-medical-records/:medicalRecordId`,
            getListByUser: isofhcare_service + '/medical-records/get-list-medical-records-by-user',
            getListByUserV2: isofhcare_service + '/admins/users/:userId/medical-profiles',
            getByUserId: `${isofhcare_service}/medical-records/get-by-user/:userId?page=1&size=10`,
            update: `${isofhcare_service}/medical-records/update-medical-records/:userId`,
        },
        department: {
            create: `${CATALOG}/department`,
            detail: `${CATALOG}/department/:departmentId`,
            remove: `${CATALOG}/department/:departmentId/remove`,
            search: `${CATALOG}/departments`,
            getByHospital: `${CATALOG}/departments/:hospitalId/hospital`,
            update: `${CATALOG}/:departmentId/department`,
            addRemoveSpecialty: `${CATALOG}/:departmentId/department/:specializationId/specialization`,
        },
        room: {
            getAll: isofhcare_service + '/room-booking/get-all',
            getByHospital: `${CATALOG}/rooms?hospitalId=:hospitalId`,
        },
        menu: {
            search: isofhcare_service + '/menu/search',
            create: isofhcare_service + '/menu/create',
            update: isofhcare_service + '/menu/update',
            delete: isofhcare_service + '/menu/delete',
            active: isofhcare_service + '/menu/set-active',
            getDetail: isofhcare_service + '/menu/detail',
            hightLight: isofhcare_service + '/menu/set-highlight',
        },
        slide: {
            search: isofhcare_service + '/slide/search',
            create: isofhcare_service + '/slide/create',
            update: isofhcare_service + '/slide/update',
            delete: isofhcare_service + '/slide/delete',
            setactive: isofhcare_service + '/slide/set-active/',
        },
        slideItem: {
            search: isofhcare_service + '/slide-item/search',
            create: isofhcare_service + '/slide-item/create',
            update: isofhcare_service + '/slide-item/update',
            delete: isofhcare_service + '/slide-item/delete',
        },
        slidePlace: {
            search: isofhcare_service + '/slide-place/search',
            create: isofhcare_service + '/slide-place/create',
            update: isofhcare_service + '/slide-place/update',
            delete: isofhcare_service + '/slide-place/delete',
        },
        news: {
            // search: isofhcare_service + "/news/search",
            // delete: isofhcare_service + "/news",
            // update: isofhcare_service + "/news",
            // create: isofhcare_service + "/news",
            // detail: isofhcare_service + "/news",
            // getById: isofhcare_service + "/news/id",
            search: '/api/news',
            delete: '/api/news',
            update: '/api/topics/topicId/news',
            detail: '/api/news/newsId',
            getById: '/news/id',
            search_by_category: '/api/topics/topicId/news',
            search_popular_news: '/api/topics/topicId/popular-news',
            list_keyword_news: '/api/topics/topicId/keywords',
            list_category: '/api/categories',
            list_category_topic: '/api/topics',
            create_category_topic: '/api/topics',
            list_category_news: '/api/news',
            create_news: '/api/news',
            detail_news: '/api/news/newId',
            details_topic: '/api/topics/topicId',
            update_news: '/api/topics/topicId/news',
            update_category: '/api/categories/categoryId/topics',
            directions: '/api/directions',
            active: '/api/news/:newsId/enable',
            inactive: '/api/news/:newsId/disable',
            exportNews: '/api/news/export',
        },

        levelCard: {
            search: '/v1/card-levels/filter',
        },

        doctor: {
            doctor_action: `${CATALOG}/doctors/doctor_id/active`,
            set_rank_doctor: `${CATALOG}/doctor/:doctorId/rank/:rank/:ordinal`,
            getAvailableRank: `${CATALOG}/doctor/rank-availible`,
            checkCertificateCode: `${CATALOG}/doctor/:certificateCode/license`,
            detail: `${CATALOG}/doctor/:doctorId`,
            update: `${CATALOG}/:doctorId/doctor`,
            search: `${CATALOG}/doctor`,
            filter: `${CATALOG}/doctors/filter`,
            generateLinkToApp: `${isofhcare_service}/deeplink/doctors/:doctorId`,
            consultationAllowed: `${CATALOG}/doctors/:doctorId/consultation`,
            getDoctorSelect: `${CATALOG}/hospitals/{hospitalId}/doctors/available?page={page}&size={size}&properties=&sort=desc&searchValue={searchValue}`,
        },

        promotion: {
            search: `${CATALOG}/promotions/search`,
            promotions: `${CATALOG}/promotions`,
        },

        notification: {
            filters: `${isofhcare_service}/notification-filters`,
            groups: `${isofhcare_service}/notification-groups`,
            events: `${isofhcare_service}/notification-events`,
            firebase_notification: `${isofhcare_service}/notification/filter`,
        },

        call: {
            search: `/videocall/v1/logs`,
            rate: `${isofhcare_service}//rate-call?callId=:callId`,
        },

        payment: {
            search: `${PAYMENT}/filter`,
            refund: `${APPOINTMENT}/:bookingId/refund`,
            checkRefunding: `${APPOINTMENT}/:bookingId/refund-status`,
            get_vendor: `${PAYMENT}/vendors`,
            export: `${PAYMENT}/report`,
            confirm: `${APPOINTMENT}/payment/:bookingId/confirm`,
        },

        disease: {
            search: `${CATALOG}/diseases/filter`,
            uploadFile: `${CATALOG}/diseases/import`,
            downloadTemplate: isofhcare_service + '/general-setting/icd-template',
        },

        specimen: {
            getAll: `${CATALOG}/specimens`,
            search: `${CATALOG}/specimens/lookup`,
            uploadFile: `${CATALOG}/specimens/import`,
            // uploadFile: `${CATALOG}/specimens`,

            // /specimen-groups
            getGroup: `${CATALOG}/specimen-groups`,
            downloadTemplate: isofhcare_service + '/general-setting/specimen-template',
        },

        country: {
            getAll: `${isofhcare_service}/country/get-all`,
        },

        nation: {
            getAll: `${isofhcare_service}/nations`,
        },

        job: {
            getAll: `${isofhcare_service}/jobs`,
        },

        question: {
            search: `${CONSULTATION}/questions/filter`,
            detail: `${CONSULTATION}/questions/:id`,
            accept: `${CONSULTATION}/questions/:questionId/acceptance`,
            sensitivity: `${CONSULTATION}/questions/:questionId/sensitivity`,
            acceptQuestions: `${CONSULTATION}/questions/acceptance`,
            reject: `${CONSULTATION}/questions/:questionId/rejection`,
            updateSpecialties: `${CONSULTATION}/questions/:questionId`,
            comment: `${CONSULTATION}/questions/:questionId/comments`,
            getStatisticsByStatus: `${CONSULTATION}/questions/statistics/by-status`,
            getStatisticsAnsweredByDoctor: `${CONSULTATION}/questions/statistics/answered-by-doctor`,
            getStatisticsAnswered: `${CONSULTATION}/questions/statistics/answered`,
            getSpecializationsAsked: `${CONSULTATION}/questions/specializations/asked`,
            generateLinkToApp: `${isofhcare_service}/deeplink/questions/:questionId`,
            exportFile: `${isofhcare_service}/report/questions`,
        },

        COVID: {
            questions: `${COVID}/api/questions`,
        },

        feature: {
            getAll: `${isofhcare_service}/features`,
            create: `${isofhcare_service}/features`,
            update: `${isofhcare_service}/features/:featureId`,
            delete: `${isofhcare_service}/features/:featureId`,
        },

        generalSettings: {
            getAll: `${isofhcare_service}/general-setting`,
            update: `${isofhcare_service}/general-setting`,
        },

        widgets: {
            getAll: `${CHART}/v1/widgets`,
            getUserTrackingWidgets: `${TRACKING}/v1/widgets`,
        },

        chartLine: {
            // getChart: `${CHART}/v1/chart-lines`,
            getChart: `${CHART}/v1/line-charts`,
            getChartData: `${TRACKING}/v1/chart-lines`,
            getPieUserTracking: `${TRACKING}/v1/pie-charts`,
        },

        top_lists: {
            getTopLists: `${CHART}/v1/top-lists`,
        },
        support: {
            search: '/news/api/news',
        },
        configHome: {
            getAll: `${isofhcare_service}/general-setting/homepage`,
            update: `${isofhcare_service}/general-setting/homepage`,
            getListBanner: `${isofhcare_service}/banner-apps`,
            getEventTypes: `${isofhcare_service}/screen-configs?type=BANNER`,
            getDetailBanner: `${isofhcare_service}/banner-apps/:bannerId`,
        },
        game: {
            get: '/promotion/v1/games/filter',
            create: '/promotion/v1/games',
            createGiftInGame: '/promotion/v1/games/:Id/gifts',
            crud: '/promotion/v1/games/:gameId',
            updateGiftInGame: '/promotion/v1/games/:gameId/gifts/:giftId',
            rewardHistory: '/promotion/v1/games/reward-history',
            exportRewardHistory: `${isofhcare_service}/reward-history`,
        },
        convenients: {
            get: '/catalog/v1/convenients/filter',
            get_dortor: '/catalog/v1/doctors/instant',
            get_convenient_transaction: '/videocall/api/emergency-call-packages',
            delete_convenient_service: '/catalog/v1/convenients/:id',
            create_update_convenient_service: '/catalog/v1/convenients',
            get_emergency_call_packages: '/videocall/api/emergency-call-packages/:id',
            get_history_emergency_call_packages: '/videocall/api/emergency-call-packages/:id/emergency-calls',
            get_convenient_by_ids: '/catalog/v1/convenients/by-ids',
        },
    },
    api: {
        revenue: {
            getRevenue: `${APPOINTMENT}/revenue-report`,
        },
        setting: {
            getConfig: `/isofhcare/redirect-configs`,
        },
        pharmacy: {
            pharmacies: `/pharmacy/api/pharmacies`,
            detailPharmacies: `/pharmacy/api/pharmacies/:id`,
            detailPaymentMethods: `/payment/v1/payment-methods/pharmacies/:id`,
            medicine_groups: `/pharmacy/api/medicine-groups`,
            medicines: `/pharmacy/api/medicines`,
            orders: `/pharmacy/api/orders`,
            prescriptions: `/pharmacy/api/prescriptions`,
            byIds: `/pharmacy/api/pharmacies`,
            exportfile: `/pharmacy/api/orders/export`,
            get_order: `/pharmacy/fulfillments/api/fulfillments`,
            create_order: `/pharmacy/api/orders/:orderId/fulfillments`,
            cancel_fulfillment: `/pharmacy/api/orders/:fulfillmentId`,
            getprofile: `/profile/api/users/:userId/profile`,
            getAllHotDeal: `/pharmacy/api/hot-deals`,
            insertData: `/pharmacy/api/hot-deals/:id/insert`,

            //delivery
            getAllMethods: `/pharmacy/api/delivery-methods`,
            getAllPartners: `/pharmacy/api/delivery-partners`,
            serviceTypes: `/pharmacy/api/delivery-partners/:partnerId/service-types`,
            methodSetting: `/pharmacy/api/delivery-methods/:methodId/settings`,
            getBranches: `${isofhcare_service}/hospital/:id/branchs`,
            getBranchesV2: `${isofhcare_service}/admins/hospitals/:id/branches`,
            hot_deal_groups: `/pharmacy/api/hot-deal-groups`,
            insertData_groups: `/pharmacy/api/hot-deal-groups/:id/insert`,
            getListChatHistory: '/consultation/chat-shopping/rooms/admin/filter',
            exportChatExcel: '/consultation/chat-shopping/rooms/:reference/messages/report',
        },
        earn_point: {
            get_config: `/promotion/v1/setting/increase-point`,
            update_config: `/promotion/v1/setting/increase-point`,
            get_member_card: `/promotion/v1/memberships/filter`,
            export_member_card: `/isofhcare/memberships`,
            get_level_card_all: `/promotion/v1/card-levels`,
            get_member_detail: `/promotion/v1/memberships/:id`,
            get_level_card: `/promotion/v1/card-levels/filter`,
            crud_level_card: `/promotion/v1/card-levels`,
            get_member_detail_history: `/promotion/v1/memberships/:id/transactions`,
            get_list_redeem_point: `/promotion/v1/loyalty-items/filter`,
            update_gift: `/promotion/v1/loyalty-items/:id`,
            create_gift: `/promotion/v1/loyalty-items`,
            get_list_redeem_future: `/promotion/v1/promotions/redemption-in-future`,
            get_detail_gift: `/promotion/v1/loyalty-items/:id`,
            get_history_gift: `/promotion/v1/loyalty-items/redemptions/filter`,
            accept_gift: `/promotion/v1/loyalty-items/redemptions/:id/send`,
            get_gift: `/promotion/v1/loyalty-items`,
            get_step_goals: `/promotion/v1/setting/step-goals`,
            delete_step_goals: `/promotion/v1/setting/step-goals/:id`,
            // create_level_card: `/promotion/v1/card-levels`,
            // update_level_card: `/promotion/v1/card-levels`,
        },
        report: {
            get_revenue_date: `/appointment/report/v1/revenue-by-date`,
            get_revenue_medical_service: `/appointment/report/v1/revenue-by-medical-service`,
            get_revenue_by_hospital: `/appointment/report/v1/revenue-by-hospital`,
            get_doctor_quality: `/consultation/doctor-quality`,
            get_total_revenue: `/appointment/report/v1/total-revenue`,
            get_total_doctor_quality: `/consultation/doctor-quality/total`,
            export_revenue_date: `/appointment/report/v1/export/revenue-by-date`,
            export_revenue_medical_service: `/appointment/report/v1/export/revenue-by-medical-service`,
            export_revenue_by_hospital: `/appointment/report/v1/export/revenue-by-hospital`,
            export_doctor_quatily: `/consultation/doctor-quality/report`,
            get_total_appointment: `/appointment/report/v1/total-appointment`,
            export_detail_report: `/appointment/report/v1/export/apppointment-detail`,
            export_medical_person: `/appointment/report/v1/export/appointment-by-patient`,
            get_oder_overTime: `/pharmacy/api/order-reports`,
            get_person: '/appointment/v1/leads/leaders',
            // get_data_lead: '/appointment/v1/leads/report-by-source'
            get_data_lead: '/appointment/v1/leads/reports',
            export_data_lead: '/appointment/v1/leads/reports/export',
            get_order_production: `/pharmacy/api/order-reports`,
            get_report_convenitent: `${PAYMENT}/convenient-orders/report-by-convenient`,
            get_utilityTrans: `${PAYMENT}/convenient-orders/report-detail`,
            export_report_convenitent: `${PAYMENT}/convenient-orders/export-by-convenient`,
            get_report_customer: `${PAYMENT}/convenient-orders/report-by-customer`,
            export_report_customer: `${PAYMENT}/convenient-orders/export-by-customer`,
            export_report_utilityTrans: `${PAYMENT}/convenient-orders/export-detail`,
            // Servey
            get_report_servey_hopital: `/appointment/report/v1/rates/hospitals`,
            export_report_servey_hopital: `/appointment/report/v1/export/rates/hospitals`,

            get_report_servey_doctor: `/appointment/report/v1/rates/doctors`,
            export_report_servey_doctor: `/appointment/report/v1/export/rates/doctors`,

            get_report_servey_detail: `/appointment/report/v1/detail-rating`,
            export_report_servey_detail: `/appointment/report/v1/export/detail-rating`,
        },
        ctkm: `/promotion/v1/promotions/filter`,
        ctkmExport: `/promotion/v1/vouchers/export`,
        gift: {
            filter: `/promotion/v1/gifts/filter`,
        },
        survey: {
            filter: `${isofhcare_service}/surveys/filter`,
            screen_config: `${isofhcare_service}/screen-configs`,
            created: `${isofhcare_service}/surveys`,
            getDetail: `${isofhcare_service}/surveys/:id`,
            createdQuestion: `${isofhcare_service}/surveys/:id/questions`,
            getSurveyResult: `${isofhcare_service}/surveys/:id/statistic`,
            getListAnwser: `${isofhcare_service}/surveys/questions/:id/answers`,
            exportExcel: `${isofhcare_service}/surveys/:id/answers/export`,
        },
        roles: `${isofhcare_service}/admins/roles`,
        permissions: `${isofhcare_service}/admins/permissions`,
        admins: `${isofhcare_service}/admins`,
        authorities: `${isofhcare_service}/user/me`,
        forgetPassword: `${isofhcare_service}/admins/forget-password`,
        changePasswordPassword: `${isofhcare_service}/admins/forget-password/:id/confirm`,
        search: `${isofhcare_service}/admins/medical-profiles/search`,
        filter: `${CATALOG}/convenients/filter`,
        logs: isofhcare_service + '/log-admin',
        logsSearch: isofhcare_service + '/admins/users/logs',
        exportExcelLogsSearch: isofhcare_service + '/admins/users/logs/exports',
        validateToken: `${API_ENDPOINT.server_url}${isofhcare_service}/validate-token`,
        user: {
            smart_search: `${CATALOG}/smart-search`,
            search_dateOfBirth: `${isofhcare_service}/admins/hospitals/:hospitalId/medical-profiles/suggest`,
            byIds: isofhcare_service + '/user/by-ids',
            uploadUser: isofhcare_service + '/user/upload-user',
            userByPhones: isofhcare_service + '/user/by-phones',
            search: isofhcare_service + '/user/search',
            filter: isofhcare_service + '/user/filter',
            login: isofhcare_service + '/user/login',
            logout: isofhcare_service + '/user/logout',
            block: isofhcare_service + '/user/block',
            create: isofhcare_service + '/user/create',
            update: isofhcare_service + '/user/update',
            active: isofhcare_service + '/user/set-active',
            detail: isofhcare_service + '/user/get-detail',
            getProfileByID: isofhcare_service + '/admins/users/:userId/profiles',
            updatePassword: isofhcare_service + '/user/update-password',
            getListBySpecialist: isofhcare_service + '/user/getListBySpecialist',
            getUserInActive: `${API_ENDPOINT.patient}/patient-filters`,
            resetPassword: isofhcare_service + '/user/reset-password/:userId',
            activeUser: isofhcare_service + '/user/active/:id',
            set_password: isofhcare_service + '/user/reset-password/',
            create_patient: isofhcare_service + '/admins/hospitals/:hospitalId/users',
            update_patient: isofhcare_service + '/admins/hospitals/:hospitalId/medical-profiles/:medicalProfileId',
            detail_patient: isofhcare_service + '/admins/hospitals/:hospitalId/users/:userId/medical-profile',
            downloadFileUsers:
                'https://isofhcare-backup.s3-ap-southeast-1.amazonaws.com/files/import-user-moi--hon-5000_8bddd623_84dd_4be2_9a2c_fc2d4e6a7a42.xlsx',
            downloadFileHssk:
                'https://isofhcare-backup.s3-ap-southeast-1.amazonaws.com/files/template-hssk-hop-dong_7565d71c_fe84_429e_9794_d49e6a8cb65d.xlsx',
            lock: isofhcare_service + '/admins/:id/locked',
            unlock: isofhcare_service + '/admins/:id/unlocked',
        },
        image: {
            upload: `${API_ENDPOINT?.serviceImages}/isofhcare/upload/image`,
        },
        file: {
            upload: isofhcare_service + '/upload/file',
        },
        file_mupti: {
            upload: `${API_ENDPOINT?.serviceImages}/isofhcare/upload/file`,
        },
        specialist: {
            getAll: isofhcare_service + '/specialist/get-all',
            search: isofhcare_service + '/specialist/search',
            create: isofhcare_service + '/specialist/create',
            delete: isofhcare_service + '/specialist/delete',
            update: isofhcare_service + '/specialist/update',
        },
        specialty: {
            all: `${CATALOG}/specializations/filter`,
            remove: `${CATALOG}/specialization/:specializationId/remove`,
            create: `${CATALOG}/specialization`,
            select: `${CATALOG}/specialization/select`,
            deleteByHospital: `${CATALOG}/:hospitalId/hospital/:specialtyId/specialization`,
            updateByHospital: `${CATALOG}/:hospitalId/hospital/:listSpecialtyId/specialization`,
            update: `${CATALOG}/:specializationId/specialization`,
        },
        specialityV2: {
            all: `/catalog/api/specializations`,
            create: `/catalog/api/specializations`,
            update: `/catalog/api/specializations/:specializationId`,
            addSymptom: `/catalog/api/specializations/:specializationId/symptoms`,
            remove: `/catalog/api/specializations/:specializationId`,
            getDetail: `/catalog/api/specializations/:specializationId`,
        },
        serviceType: {
            getAll: isofhcare_service + '/service-type/get-all',
            search: isofhcare_service + '/service-type/search',
            create: isofhcare_service + '/service-type/create',
            delete: isofhcare_service + '/service-type/delete',
            update: isofhcare_service + '/service-type/update',
        },
        service: {
            search: isofhcare_service + '/service/search',
            byIds: CATALOG + '/medicalservices/by-ids',
            type: isofhcare_service + '/service-type/search',
            special: isofhcare_service + '/specialist/get-all',
            create: isofhcare_service + '/service/create',
            update: isofhcare_service + '/service/update',
            delete: isofhcare_service + '/service/delete',
            getAll: isofhcare_service + '/service/get-all',
            getByHospital: `${CATALOG}/medicalservice`,
            get_list_service: '/medicalservice',
            getByHospitalId: `${CATALOG}/medicalservice/:hospitalId/hospital`,
            detail: `${CATALOG}/medicalservice/:medicalserviceId`,
            searchMedicalService: `${CATALOG}/medicalservice/search`,
            highlight: `${CATALOG}/medicalservice/:medicalserviceId/highlight`,
            rejection: `${CATALOG}/medicalservice/:medicalserviceId/rejection`,
            approval: `${CATALOG}/medicalservice/:medicalserviceId/approval`,
            assignPromotion: `${CATALOG}/medicalservice/:medicalserviceId/assign-promotion?promotionId=:promotionId`,
            getMedicalServicesByHospitalId: `${CATALOG}/medicalservice/search?hospitalId=:hospitalId`,
            importRevenue: `${CATALOG}/medicalservices/import-revenue-percent`,
            getListSchedule: `${CATALOG}/medicals/:serviceId/timeslots`,
            editFees: CATALOG + '/medicalservices/fees',
            exportService: `${CATALOG}/medicalservices/report`,
            importService: `${CATALOG}/medicalservices/import-revenue-percent`,
            selectMedicalService: `${CATALOG}/medicalservices/select`,
        },
        post: {
            search: isofhcare_service + '/post/search',
            assign: isofhcare_service + '/post/assign',
            approved: isofhcare_service + '/post/approved-post',
            approvedList: isofhcare_service + '/post/assign-list-post',
        },
        role: {
            search: isofhcare_service + '/roles/search',
            create: isofhcare_service + '/roles/create',
            delete: isofhcare_service + '/roles/delete',
            block: isofhcare_service + '/roles/block',
            getDetail: isofhcare_service + '/roles/get-detail',
            update: isofhcare_service + '/roles/update',
        },
        permission: {
            PARTNER_PHARMACY: '/admin/pharmacies',
            IRIS_PHARMACY: '/admin/pharmacies',
            ADMIN_MANAGER_DASHBOARD: '/admin/dashboard/booking',
            ADMIN_MANAGER_ADMIN: '/admin/admin-list',
            ADMIN_MANAGER_USER: '/admin/user',
            ADMIN_MANAGER_HOSPITAL: '/admin/hospitals',
            ADMIN_MANAGER_CONTENT: '/admin/news-category',
            ADMIN_MANAGER_PROMOTION_VOUCHER: '/admin/list-service',
            ADMIN_MANAGER_NOTIFICATION: '/admin/notification-filter',
            ADMIN_CONSULTATION: '/admin/question',
            ADMIN_MANAGER_MEDICAL_DATA: '/admin/ICD',
            ADMIN_MANAGER_SETTING: '/admin/menu',
            ADMIN_MANAGER_LOG: '/admin/logs',
            ADMIN_MANAGER_APPOINTMENT: 'admin/booking',
            ADMIN_MANAGER_CONSULTATION_GROUP: '/admin/list-team',
        },
        comment: {
            search: isofhcare_service + '/comment/search',
            create: isofhcare_service + '/comment/create',
            delete: isofhcare_service + '/comment/delete',
            update: isofhcare_service + '/comment/update',
            acceptAsSolution: isofhcare_service + '/comment/accept-as-solution',
        },
        hospital: {
            getAll: isofhcare_service + '/hospital/get-all',
            getAllTracking: isofhcare_service + '/hospital/get-all?enable=true',
            create: isofhcare_service + '/hospital/create',
            delete: isofhcare_service + '/hospital/delete',
            getDetail: isofhcare_service + '/hospital/get-detail',
            getDetailHopital: isofhcare_service + '/admins/hospitals',
            search: isofhcare_service + '/hospital/search',
            select: CATALOG + '/hospitals/select',
            byIds: CATALOG + '/hospitals/by-ids',
            getForTable: isofhcare_service + '/admins/hospitals',
            update: isofhcare_service + '/hospital/update',
            active: isofhcare_service + '/hospital/set-active',
            set_ticket: isofhcare_service + '/hospital/manager-reception',
            get_info_ticket: isofhcare_service + '/number-hospital/history',
            getHospitalbyDoctor: isofhcare_service + '/hospital/get-hospital-by-doctor',
            set_rank_hospital: `${isofhcare_service}/hospital/:hospitalId/rank/:rank/:ordinal`,
            get_list_hospital: CATALOG + '/hospitals',
            getAvailableRank: `${isofhcare_service}/hospital/rank-availible`,
            getVendorUsers: `${isofhcare_service}/hospital/:hospitalId/users`,
            createVendorUser: `${isofhcare_service}/hospital/:hospitalId/users`,
            changeVendorUserPassword: `${isofhcare_service}/hospital/:hospitalId/users/:userId/password`,
            activeVendorUser: `${isofhcare_service}/hospital/:hospitalId/users/:userId/active`,
            updateGeneralDisplayInfo: `${isofhcare_service}/hospital/:hospitalId/general`,
            updateContacts: `${isofhcare_service}/hospital/:hospitalId/contact`,
            // updatePayment: `${isofhcare_service}/hospital/:hospitalId/payment`,
            updatePayment: `/payment/v1/payment-methods/hospitals/:hospitalId`,
            updatePaymentKeys: `/payment/v1/hospitals/:hospitalId/payment`,
            chdk: `${isofhcare_service}/hospital/cau-hinh-dat-kham/`,
            orderNoti: `/order-notification/v1/email/`,
            updateSpecialistByHospital: `${CATALOG}/:hospitalId/hospital/:specializationIds/specialization`,
            getVendorPermission: isofhcare_service + '/vendor/:id/permissions',
            updateVendorPermission: isofhcare_service + '/vendor/:id/permissions',
            lookup_medical_record: isofhcare_service + '/admins/hospital-support-lookup-medical-records',
            getServicePackages: isofhcare_service + '/service-packages',
            getServicePackageCurrent: isofhcare_service + '/service-packages/hospitals/:id',
        },
        wallet: {
            detail: wallet_services + '/partners',
            getDetail: wallet_services + '/online-transactions',
        },
        doctorInf: {
            active: isofhcare_service + '/doctorInf/active',
            create: isofhcare_service + '/doctorInf/create',
            // reject: isofhcare_service + "/doctorInf/reject",
            search: isofhcare_service + '/doctorInf/search',
            get_all: CATALOG + '/doctors/filter',
            confirm: CATALOG + '/doctors/confirm',
            reject: CATALOG + '/doctors/reject',
        },
        booking: {
            search: isofhcare_service + '/booking/search',
            approved: isofhcare_service + '/booking/approved-booking',
            update: isofhcare_service + '/booking/update',
            arrival: isofhcare_service + '/booking/update-arrival',
            checkin: isofhcare_service + '/booking/checkin',
            checkTransfer: isofhcare_service + '/booking/payTranfer',
            getDetail: isofhcare_service + '/booking/get-detail',
            get: APPOINTMENT,
            detail: `${APPOINTMENT}/:bookingId`,
            reject: `${APPOINTMENT}/:bookingId/reject`,
            approve: `${APPOINTMENT}/:bookingId/accept`,
            checkIn: `${APPOINTMENT}/:bookingId/checkin`,
            revert: `${APPOINTMENT}/:bookingId/revert`,
            updateBooking: `${APPOINTMENT}/:bookingId`,
            getDetailProfile: `${API_ENDPOINT.server_url}/profile/api/profiles/:userProfileId`,
            getBySchedule: `${APPOINTMENT}/doctor/:scheduleId/:date/schedule?page=:page&size=:size&sort=desc&properties=created`,
            getScheduleByDoctor: `${CATALOG}/schedules/block-times`,
            createBooking: `${APPOINTMENT}/admin`,
            exportExcel: `${APPOINTMENT}/report`,
            listsource: `${APPOINTMENT}/sources`,
            listUsers: `${APPOINTMENT}/admin/created-users`,
            getLead: `${APPOINTMENT}/leads/filter`,
            exportLead: `${APPOINTMENT}/leads/export`,
            createLead: `${APPOINTMENT}/leads`,
            getStatus: `${APPOINTMENT}/leads/status`,
            getSource: `${APPOINTMENT}/leads/sources`,
            getPersonCharge: `${APPOINTMENT}/leads/leaders`,
            leadBooking: `${APPOINTMENT}/leads/:id/appointments`,
        },
        zone: {
            getAll: isofhcare_service + '/zone/get-by-district',
            getByDistrict: isofhcare_service + '/zone/get-by-district/:districtId',
        },
        province: {
            getAll: isofhcare_service + '/province/get-all',
        },
        district: {
            getAll: isofhcare_service + '/district/get-all',
            getProvince: isofhcare_service + '/district/get-by-province',
            getByProvince: isofhcare_service + '/district/get-by-province/:provinceId',
        },
        medicine_category: {
            getAll: isofhcare_service + '/medicine-category/get-all',
            search: isofhcare_service + '/medicine-category/search',
            create: isofhcare_service + '/medicine-category/create',
            delete: isofhcare_service + '/medicine-category/delete',
            update: isofhcare_service + '/medicine-category/update',
        },
        medicine_store: {
            getAll: isofhcare_service + '/medicine-store/get-all',
            search: 'drugstores',
            searchExpression: 'drugstore/search',
            create: 'drugstore',
            get_drug: 'medicine/idStore/drugstore',
            updateLocation: 'drugstore/idStore/location',
            delete: isofhcare_service + '/medicine-store/delete',
            update: isofhcare_service + '/medicine-store/update',
            getAllMedicines: isofhcare_service + '/medicine-store/medicines/get-all',
            searchListDrug: isofhcare_service + '/orders/v1/prescriptions/search',
        },
        medicine_controll: {
            getAll: '/medicine/references',
        },
        userTracking: {
            search: isofhcare_service + '/user-tracking/search',
            total: isofhcare_service + '/user/total-user',
        },
        upload: {
            import: isofhcare_service + '/user/import-user',
            importHssk: isofhcare_service + '/admins/hospitals/:hospitalId/medical-record-imports',
        },
        advertise: {
            search: isofhcare_service + '/advertise/search',
            create: isofhcare_service + '/advertise/create',
            delete: isofhcare_service + '/advertise/delete',
        },
        category: {
            search: `${CATALOG}/categories/search`,
            getAll: `${CATALOG}/categories`,
            create: `${CATALOG}/categories`,
            update: `${CATALOG}/categories/:categoryId`,
            delete: `${CATALOG}/categories/:categoryId`,
        },
        message: {
            getAll: `${isofhcare_service}/admins/messages/report`,
            pushMessage: `${isofhcare_service}/admins/messages/push`,
        },
        voucher: {
            search: `${isofhcare_service}/voucher`,
            create: `${isofhcare_service}/voucher`,
            update: `${isofhcare_service}/voucher/:id`,
            getHistory: `${isofhcare_service}/voucher/history/:voucherId`,
            importUser: `${isofhcare_service}/voucher/create/available`,
            generate: `${isofhcare_service}/voucher/generate`,
            available: `${isofhcare_service}/voucher/available/:voucherId`,
        },

        medicalRecord: {
            getDetail: `${isofhcare_service}/medical-records/get-detail-medical-records/:medicalRecordId`,
            getListByUser: isofhcare_service + '/medical-records/get-list-medical-records-by-user',
            getListByUserV2: isofhcare_service + '/admins/users/:userId/medical-profiles',
            getByUserId: `${isofhcare_service}/medical-records/get-by-user/:userId?page=1&size=10`,
            update: `${isofhcare_service}/medical-records/update-medical-records/:userId`,
        },
        department: {
            create: `${CATALOG}/department`,
            detail: `${CATALOG}/department/:departmentId`,
            remove: `${CATALOG}/department/:departmentId/remove`,
            search: `${CATALOG}/departments`,
            getByHospital: `${CATALOG}/departments/:hospitalId/hospital`,
            update: `${CATALOG}/:departmentId/department`,
            addRemoveSpecialty: `${CATALOG}/:departmentId/department/:specializationId/specialization`,
        },
        room: {
            getAll: isofhcare_service + '/room-booking/get-all',
            getByHospital: `${CATALOG}/rooms?hospitalId=:hospitalId`,
        },
        menu: {
            search: isofhcare_service + '/menu/search',
            create: isofhcare_service + '/menu/create',
            update: isofhcare_service + '/menu/update',
            delete: isofhcare_service + '/menu/delete',
            active: isofhcare_service + '/menu/set-active',
            getDetail: isofhcare_service + '/menu/detail',
            hightLight: isofhcare_service + '/menu/set-highlight',
        },
        slide: {
            search: isofhcare_service + '/slide/search',
            create: isofhcare_service + '/slide/create',
            update: isofhcare_service + '/slide/update',
            delete: isofhcare_service + '/slide/delete',
            setactive: isofhcare_service + '/slide/set-active/',
        },
        slideItem: {
            search: isofhcare_service + '/slide-item/search',
            create: isofhcare_service + '/slide-item/create',
            update: isofhcare_service + '/slide-item/update',
            delete: isofhcare_service + '/slide-item/delete',
        },
        slidePlace: {
            search: isofhcare_service + '/slide-place/search',
            create: isofhcare_service + '/slide-place/create',
            update: isofhcare_service + '/slide-place/update',
            delete: isofhcare_service + '/slide-place/delete',
        },
        news: {
            // search: isofhcare_service + "/news/search",
            // delete: isofhcare_service + "/news",
            // update: isofhcare_service + "/news",
            // create: isofhcare_service + "/news",
            // detail: isofhcare_service + "/news",
            // getById: isofhcare_service + "/news/id",
            search: '/api/news',
            delete: '/api/news',
            update: '/api/topics/topicId/news',
            detail: '/api/news/newsId',
            getById: '/news/id',
            search_by_category: '/api/topics/topicId/news',
            search_popular_news: '/api/topics/topicId/popular-news',
            list_keyword_news: '/api/topics/topicId/keywords',
            list_category: '/api/categories',
            list_category_topic: '/api/topics',
            list_category_topicV2: '/news/api/topics',
            create_category_topic: '/api/topics',
            list_category_news: '/api/news',
            create_news: '/api/news',
            detail_news: '/api/news/newId',
            details_topic: '/api/topics/topicId',
            update_news: '/api/topics/topicId/news',
            update_category: '/api/categories/categoryId/topics',
            directions: '/api/directions',
            active: '/api/news/:newsId/enable',
            inactive: '/api/news/:newsId/disable',
            exportNews: '/api/news/export',
        },

        levelCard: {
            search: '/v1/card-levels/filter',
        },

        doctor: {
            doctor_action: `${CATALOG}/doctors/doctor_id/active`,
            set_rank_doctor: `${CATALOG}/doctor/:doctorId/rank/:rank/:ordinal`,
            getAvailableRank: `${CATALOG}/doctor/rank-availible`,
            checkCertificateCode: `${CATALOG}/doctor/:certificateCode/license`,
            detail: `${CATALOG}/doctor/:doctorId`,
            update: `${CATALOG}/:doctorId/doctor`,
            search: `${CATALOG}/doctor`,
            filter: `${CATALOG}/doctors/filter`,
            generateLinkToApp: `${isofhcare_service}/deeplink/doctors/:doctorId`,
            consultationAllowed: `${CATALOG}/doctors/:doctorId/consultation`,
            getDoctorSelect: `${CATALOG}/hospitals/{hospitalId}/doctors/available?page={page}&size={size}&properties=&sort=desc&searchValue={searchValue}`,
            getChatTrial: `${CATALOG}/doctors/:doctorId/instant-chat-trial`,
        },

        promotion: {
            search: `${CATALOG}/promotions/search`,
            promotions: `${CATALOG}/promotions`,
        },

        notification: {
            filters: `${isofhcare_service}/notification-filters`,
            groups: `${isofhcare_service}/notification-groups`,
            events: `${isofhcare_service}/notification-events`,
            firebase_notification: `${isofhcare_service}/notification/filter`,
            watch_notification: `${isofhcare_service}/notification/active-watched`,
            count_notification: `${isofhcare_service}/notification/admin/count-notification`,
            export_excel: `${isofhcare_service}/notification-groups/:id/export`,
        },

        call: {
            search: `/videocall/v1/logs`,
            rate: `${isofhcare_service}//rate-call?callId=:callId`,
        },

        payment: {
            search: `${PAYMENT}/filter`,
            refund: `${APPOINTMENT}/:bookingId/refund`,
            checkRefunding: `${APPOINTMENT}/:bookingId/refund-status`,
            get_vendor: `${PAYMENT}/vendors`,
            export: `${PAYMENT}/report`,
            confirm: `${APPOINTMENT}/payment/:bookingId/confirm`,
        },

        disease: {
            search: `${CATALOG}/diseases/filter`,
            uploadFile: `${CATALOG}/diseases/import`,
            downloadTemplate: isofhcare_service + '/general-setting/icd-template',
        },

        specimen: {
            getAll: `${CATALOG}/specimens`,
            search: `${CATALOG}/specimens/lookup`,
            uploadFile: `${CATALOG}/specimens/import`,
            // uploadFile: `${CATALOG}/specimens`,

            // /specimen-groups
            getGroup: `${CATALOG}/specimen-groups`,
            downloadTemplate: isofhcare_service + '/general-setting/specimen-template',
        },

        country: {
            getAll: `${isofhcare_service}/country/get-all`,
        },

        nation: {
            getAll: `${isofhcare_service}/nations`,
        },

        job: {
            getAll: `${isofhcare_service}/jobs`,
        },

        question: {
            search: `${CONSULTATION}/questions/filter`,
            detail: `${CONSULTATION}/questions/:id`,
            changeDoctor: `${CONSULTATION}/questions/:id/doctor`,
            hideComment: `${CONSULTATION}/questions/comments/:id/hidden`,
            accept: `${CONSULTATION}/questions/:questionId/acceptance`,
            sensitivity: `${CONSULTATION}/questions/:questionId/sensitivity`,
            acceptQuestions: `${CONSULTATION}/questions/acceptance`,
            reject: `${CONSULTATION}/questions/:questionId/rejection`,
            updateSpecialties: `${CONSULTATION}/questions/:questionId`,
            comment: `${CONSULTATION}/questions/:questionId/comments`,
            getStatisticsByStatus: `${CONSULTATION}/questions/statistics/by-status`,
            getStatisticsAnsweredByDoctor: `${CONSULTATION}/questions/statistics/answered-by-doctor`,
            getStatisticsAnswered: `${CONSULTATION}/questions/statistics/answered`,
            getSpecializationsAsked: `${CONSULTATION}/questions/specializations/asked`,
            generateLinkToApp: `${isofhcare_service}/deeplink/questions/:questionId`,
            exportFile: `${CONSULTATION}/questions/report`,
        },

        COVID: {
            questions: `${COVID}/api/questions`,
        },

        feature: {
            getAll: `${isofhcare_service}/features`,
            create: `${isofhcare_service}/features`,
            update: `${isofhcare_service}/features/:featureId`,
            delete: `${isofhcare_service}/features/:featureId`,
        },

        generalSettings: {
            getAll: `${isofhcare_service}/general-setting`,
            update: `${isofhcare_service}/general-setting`,
        },

        widgets: {
            getAll: `${CHART}/v1/widgets`,
            getUserTrackingWidgets: `${TRACKING}/v1/widgets`,
        },

        chartLine: {
            // getChart: `${CHART}/v1/chart-lines`,
            getChart: `${CHART}/v1/line-charts`,
            getChartData: `${TRACKING}/v1/chart-lines`,
            getPieUserTracking: `${TRACKING}/v1/pie-charts`,
        },

        top_lists: {
            getTopLists: `${CHART}/v1/top-lists`,
        },
        support: {
            search: '/news/api/news',
        },
        configHome: {
            getAll: `${isofhcare_service}/general-setting/homepage`,
            update: `${isofhcare_service}/general-setting/homepage`,
            getListBanner: `${isofhcare_service}/banner-apps`,
            getEventTypes: `${isofhcare_service}/screen-configs?type=BANNER`,
            getSlideEventTypes: `${isofhcare_service}/screen-configs?type=SLIDE`,
            getDetailBanner: `${isofhcare_service}/banner-apps/:bannerId`,
            popupApp: `${isofhcare_service}/popup-apps`,
        },
        game: {
            get: '/promotion/v1/games/filter',
            create: '/promotion/v1/games',
            createGiftInGame: '/promotion/v1/games/:Id/gifts',
            crud: '/promotion/v1/games/:gameId',
            updateGiftInGame: '/promotion/v1/games/:gameId/gifts/:giftId',
            rewardHistory: '/promotion/v1/games/reward-history',
            exportRewardHistory: '/promotion/v1/games/export-reward-history',
        },
        accountCompany: {
            getAll: '/business/v1/companies',
            getResertPass: '/isofhcare/user/reset-password',
            getPersonCompamy: '/business/v1/employees',
            // get_deactivate: '/business/v1/companies',
            // get_activate: '/business/v1/companies'
        },
        convenients: {
            get: '/catalog/v1/convenients/filter',
            get_order: '/payment/v1/convenient-orders/filter',
            get_order_by_reference: '/payment/v1/convenient-orders/:reference',
            get_session: '/consultation/chat-convenients/sessions/filter',
            get_session_report: '/consultation/chat-convenients/sessions/report',
            get_session_report_detail: '/consultation/chat-convenients/sessions/report-detail',
            get_dortor: '/catalog/v1/doctors/instant',
            get_convenient_transaction: '/videocall/api/emergency-call-packages',
            export_excel_transaction: '/payment/v1/convenient-orders/report',
            get_emergency_calls: '/videocall/api/emergency-calls',
            delete_convenient_service: '/catalog/v1/convenients/:id',
            create_update_convenient_service: '/catalog/v1/convenients',
            get_emergency_call_packages: '/videocall/api/emergency-call-packages/:id',
            get_history_emergency_call_packages: '/videocall/api/emergency-call-packages/:id/emergency-calls',
            get_convenient_by_ids: '/catalog/v1/convenients/by-ids',
            get_symptom: '/catalog/api/symptoms',
            create_symptom: '/catalog/api/symptoms',
        },
        remindMedicine: {
            getAll: `${isofhcare_service}/v2/remind-medicines/filter`,
            detail: `${isofhcare_service}/v2/remind-medicines/:id`,
        },
        ai: {
            FILTER: `/${isofhcare_service}/ai-analysis/filter`,
        },
        groups: {
            getGroups: `${CONSULTATION}/groups`,
            getGroupsById: `${CONSULTATION}/groups/:id`,
            addSliderById: `${CONSULTATION}/groups/:id/slides`,
            getAllGroups: `${CONSULTATION}/groups/filter`,
            getGroupsMember: `${CONSULTATION}/groups/:id/members`,
            deleteMemberById: `${CONSULTATION}/groups/:id/members/:userId`,
            getListArticle: `${CONSULTATION}/groups/:id/posts/filter`,
            addArticle: `${CONSULTATION}/groups/:id/posts`,
            updateArticle: `${CONSULTATION}/groups/posts/:id`,
            getArticleById: `${CONSULTATION}/groups/posts/:id`,
            getCommentById: `${CONSULTATION}/groups/posts/:id/comments/filter`,
            showHideComment: `${CONSULTATION}/groups/comments/:id/active`,
            showHideArticle: `${CONSULTATION}/groups/posts/:id/active`,
        },
        userTracking: {
            rpTracking: '/user-dashboard/v1/line-charts',
            toplist: '/user-dashboard/v1/top-lists',
            getSource: `/${isofhcare_service}/admins/hospitals?styleList=1&decouple=1`,
            getSourceV2: `/${isofhcare_service}/admins/hospitals?styleList=1`,
        },
    },
    permission: {
        PARTNER_PHARMACY: '/admin/pharmacies',
        IRIS_PHARMACY: '/admin/pharmacies',
        ADMIN_MANAGER_DASHBOARD: '/admin/dashboard/booking',
        ADMIN_MANAGER_ADMIN: '/admin/admin-list',
        ADMIN_MANAGER_USER: '/admin/user',
        ADMIN_MANAGER_HOSPITAL: '/admin/hospitals',
        ADMIN_MANAGER_CONTENT: '/admin/news-category',
        ADMIN_MANAGER_PROMOTION_VOUCHER: '/admin/list-service',
        ADMIN_MANAGER_NOTIFICATION: '/admin/notification-filter',
        ADMIN_CONSULTATION: '/admin/question',
        ADMIN_MANAGER_MEDICAL_DATA: '/admin/ICD',
        ADMIN_MANAGER_SETTING: '/admin/menu',
        ADMIN_MANAGER_LOG: '/admin/logs',
        ADMIN_MANAGER_APPOINTMENT: 'admin/booking',
        ADMIN_MANAGER_CONSULTATION_GROUP: '/admin/list-team',
    },
};
