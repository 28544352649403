import { FIREBASE_CONFIG } from '../firebase-config';
import * as firebase from 'firebase/app';
import * as messaging from 'firebase/messaging';

firebase.initializeApp(FIREBASE_CONFIG.config);

export const getMessageToken = async () => {
    //   try {
    //     await Notification.requestPermission()
    //     const token = await firebase.messaging().getToken();
    //     return token
    //   } catch (error) {
    //     console.log(error, 'err')
    //   }
};

export const onReceiveMessage = async (onReceiveMessage) => {
    //   firebase.messaging().onMessage(onReceiveMessage)
};
